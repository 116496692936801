<template>
  <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M99.7656 184.219C146.278 184.219 183.984 146.513 183.984 100C183.984 53.4873 146.278 15.7812 99.7656 15.7812C53.2529 15.7812 15.5469 53.4873 15.5469 100C15.5469 146.513 53.2529 184.219 99.7656 184.219Z" fill="#EBEBEB" />
    <path d="M177.773 184.219H183.984" stroke="#212121" stroke-width="2.34375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M30.1562 184.219H171.367" stroke="#212121" stroke-width="2.34375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.5469 184.219H23.7109" stroke="#212121" stroke-width="2.34375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M146.68 97.3828C154.23 97.3828 160.352 91.2617 160.352 83.7109C160.352 76.1602 154.23 70.0391 146.68 70.0391C139.129 70.0391 133.008 76.1602 133.008 83.7109C133.008 91.2617 139.129 97.3828 146.68 97.3828Z" fill="white" />
    <path d="M158.945 114.258V173.867C158.945 179.375 154.492 183.828 148.984 183.828H50.5469C45.0391 183.828 40.5859 179.375 40.5859 173.867V114.258C40.5859 113.203 40.7812 112.148 41.2109 111.172C41.6406 110.117 42.2656 109.141 43.125 108.359L90.9766 62.6953C95.8984 58.0078 103.633 58.0078 108.594 62.6953L156.445 108.359C157.305 109.18 157.93 110.117 158.359 111.172C158.711 112.148 158.945 113.203 158.945 114.258Z" fill="currentColor" stroke="#212121" stroke-width="2.34375" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M158.32 111.211L141.289 122.695L114.141 140.977C105.469 146.836 94.1016 146.836 85.3906 140.977L58.2422 122.695L41.2109 111.211" stroke="#212121" stroke-width="2.34375" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M80.5469 163.359L83.6719 165.781" stroke="#212121" stroke-width="0.976562" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M80.5469 160.352L86.8359 165.156" stroke="#212121" stroke-width="0.976562" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M85.0781 160.781L86.8359 162.109" stroke="#212121" stroke-width="0.976562" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M153.359 136.289V145.078" stroke="#212121" stroke-width="1.17188" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M153.359 129.062V131.367" stroke="#212121" stroke-width="1.17188" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M155 175.117C154.648 176.875 153.398 178.32 151.758 178.945" stroke="#212121" stroke-width="1.17188" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M141.289 105.781V122.695L109.844 143.867C103.75 147.969 95.8203 147.969 89.7266 143.867L58.2812 122.695V105.781C58.2812 105.039 58.8672 104.453 59.6094 104.453H139.961C140.664 104.414 141.289 105.039 141.289 105.781Z" fill="white" stroke="#212121" stroke-width="2.34375" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M79.0234 129.062H120.508" stroke="#212121" stroke-width="2.34375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M79.0234 123.32H120.508" stroke="#212121" stroke-width="2.34375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M79.0234 117.539H120.508" stroke="#212121" stroke-width="2.34375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M96.4062 67.2656C98.2813 65.4687 101.25 65.4687 103.125 67.2656" stroke="#212121" stroke-width="1.17188" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M141.289 93.9453C148.84 93.9453 154.961 87.8242 154.961 80.2734C154.961 72.7227 148.84 66.6016 141.289 66.6016C133.738 66.6016 127.617 72.7227 127.617 80.2734C127.617 87.8242 133.738 93.9453 141.289 93.9453Z" :fill="errorColor" stroke="#212121" stroke-width="2.34375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M166.641 66.25L163.359 68.2422" stroke="white" stroke-width="2.73438" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M168.75 71.7188L165.352 71.7969" stroke="white" stroke-width="2.73438" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M160.742 65.0781L162.227 61.7578" stroke="white" stroke-width="2.73438" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M129.453 63.6719C137.004 63.6719 143.125 57.5508 143.125 50C143.125 42.4492 137.004 36.3281 129.453 36.3281C121.902 36.3281 115.781 42.4492 115.781 50C115.781 57.5508 121.902 63.6719 129.453 63.6719Z" fill="white" />
    <path d="M123.867 59.4531C131.418 59.4531 137.539 53.332 137.539 45.7812C137.539 38.2305 131.418 32.1094 123.867 32.1094C116.316 32.1094 110.195 38.2305 110.195 45.7812C110.195 53.332 116.316 59.4531 123.867 59.4531Z" :fill="infoLightenColor" stroke="#212121" stroke-width="2.34375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M120.352 22.1875L119.688 25.5078" stroke="white" stroke-width="2.73438" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M114.531 23.1641L115.82 26.7969" stroke="white" stroke-width="2.73438" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M112.227 28.7109L109.258 26.5625" stroke="white" stroke-width="2.73438" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M92.6953 54.8828C100.246 54.8828 106.367 48.7617 106.367 41.2109C106.367 33.6602 100.246 27.5391 92.6953 27.5391C85.1445 27.5391 79.0234 33.6602 79.0234 41.2109C79.0234 48.7617 85.1445 54.8828 92.6953 54.8828Z" fill="white" />
    <path d="M86.0938 52.0703C93.6445 52.0703 99.7656 45.9492 99.7656 38.3984C99.7656 30.8477 93.6445 24.7266 86.0938 24.7266C78.543 24.7266 72.4219 30.8477 72.4219 38.3984C72.4219 45.9492 78.543 52.0703 86.0938 52.0703Z" :fill="cpmGoalColor" stroke="#212121" stroke-width="2.34375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M67.1875 48.7891L64.9609 51.6797" stroke="white" stroke-width="2.73438" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M61.7188 46.3281L65.3516 45.1172" stroke="white" stroke-width="2.73438" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M60.8984 40.4688L64.2188 41.2109" stroke="white" stroke-width="2.73438" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M139.57 77.4219C139.57 77.4219 142.109 75.7031 143.086 77.9297C144.18 80.4297 139.062 83.7891 139.062 83.7891H143.516" stroke="white" stroke-width="1.17188" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M126.641 46.9141H120L124.727 41.0156V49.2578" stroke="white" stroke-width="1.17188" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M82.8906 36.1328L85.4688 33.6328V41.9141" stroke="white" stroke-width="1.17188" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
  export default {
    name: 'ConfirmEmailImage',
    computed: {
      cpmGoalColor() {
        return this.$vuetify.theme.themes.light['cpm-goal-color']
      },
      infoLightenColor() {
        return this.$vuetify.theme.themes.light['info-lighten']
      },
      errorColor() {
        return this.$vuetify.theme.themes.light.error
      }
    }
  }
</script>
