<template>
  <v-card class="text-center black--text" max-width="350" elevation="0">
    <confirm-email-image class="primary--text" />
    <div class="text-h5 text-uppercase font-weight-bold mt-7">
      {{ $t('confirm_email.confirm_your_email') }}
    </div>
    <div class="mt-7">
      {{ $t('confirm_email.sent_verification_link') }} <span class="font-weight-bold">{{ email }}</span>
    </div>
    <div class="mt-3">
      {{ $t('confirm_email.description') }}
    </div>
    <c-btn
      color="primary"
      depressed
      :loading="isLoading || fetchIsLoading"
      large
      class="mt-7"
      :disabled="isDisabled"
      :label="$t('confirm_email.confirm_email')"
      @click="confirmEmail()"
    />
    <div :class="['text-body-2 secondary-darken--text mt-2', { timer: !time }]">
      {{ displayTime }}
    </div>

    <c-btn text @click="logout()">
      <span class="text-body-2 text-decoration-underline">{{ $t('main.back') }}</span>
    </c-btn>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import ConfirmEmailImage from '@/views/ConfirmEmail/ConfirmEmailImage.vue'
  import handleErrors from '@/services/handleErrors.js'
  import { personalRepository } from '@/services/repository-factory.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'ConfirmEmail',
    components: { CBtn, ConfirmEmailImage },
    data() {
      return {
        isLoading: false,
        isDisabled: false,
        TIME_TOTAL: 60,
        timerId: null,
        startDate: null,
        time: 0
      }
    },
    computed: {
      ...mapState('personal', ['email', 'fetchIsLoading']),
      displayTime() {
        const seconds = this.time < 10 ? `0${this.time}` : this.time
        return `00:${seconds}`
      }
    },
    created() {
      gtmPush({ event: 'ab_test_confirm_email_open_page' })
      this.fetchUserProfile()
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile']),
      async confirmEmail() {
        gtmPush({ event: 'ab_test_confirm_email_resend_button' })
        this.isLoading = true
        try {
          await personalRepository.sendEmailConfirmation()
          this.startDate = new Date().getTime() / 1000
          this.isDisabled = true
          this.timerId = setInterval(this.updateTimer, 1000)
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      },
      updateTimer() {
        const currentDate = new Date().getTime() / 1000
        const diff = currentDate - this.startDate
        if (diff <= this.TIME_TOTAL) {
          this.time = Math.round(this.TIME_TOTAL - diff)
        } else {
          this.isDisabled = false
          clearInterval(this.timerId)
          this.timerId = null
          this.time = 0
        }
      },
      async logout() {
        await this.$auth.logout()
        this.$router.push({ name: routeNames.REGISTER })
      }
    }
  }
</script>

<style scoped lang="scss">
.timer {
  visibility: hidden;
}
</style>
